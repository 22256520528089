import React from "react";
import { ReactSVG } from "react-svg";

const FeatureCard = ({ num, icon, name, desc }) => {
  return (
    <div className=" h-[500px] flex flex-col border-[2px] border-[#161C3D] items-center gap-[33px] justify-center relative px-[25px]">
      <h3 className="absolute left-[25px] top-0 text-[24px] font-[700]">
        {`0` + num}
      </h3>
      <div className="flex flex-col items-center gap-[7px] h-[25%]">
        <ReactSVG src={icon} />
        <h3 className="font-[700] text-[24px] text-center">{name}</h3>
      </div>

      <p className="text-[18px] font-[500] text-[#0b0721] text-center h-[35%]">
        {desc}
      </p>
    </div>
  );
};

export default FeatureCard;
