import React from "react";
import logo from "../assets/images/humfinance-logo.svg";
import mockup from "../assets/images/mockup-1.svg";

const HeadlineSection = () => {
  return (
    <div className=" bg-[#0b0721] text-[#FFFFFF] pb-10">
      <div className="mx-[72px] text-md font-[500] py-10 flex items-center justify-between">
        <div className=" flex items-center gap-1">
          <h2>Hum</h2>
          <img src={logo} alt="" width={20} />
          <h2>Finance</h2>
        </div>

        <ul className="flex text-gray-400 gap-7">
          <li>
            <a href="" className=" hover:text-white">
              Ecosystem
            </a>
          </li>
          <li>
            <a href="" className=" hover:text-white">
              Community
            </a>
          </li>
          <li>
            <a href="" className=" hover:text-white">
              Developers
            </a>
          </li>
          <li>
            <a href="" className=" hover:text-white">
              FAQ
            </a>
          </li>

          <li>
            <a
              href="https://docs.google.com/document/d/1IfVy6WsxqvggyHBpyhzL54b4eDMR0ZjG6AqF2vFWL2Q/edit?usp=sharing"
              target="_blank"
              rel="noreferrer"
              className=" hover:text-white"
            >
              Whitepaper
            </a>
          </li>
        </ul>

        <button className="bg-[#FF7F50] px-5 py-3 rounded-md">
          Download Now
        </button>
      </div>
      <div className="flex justify-center items-center w-[85%] m-auto text-center text-[#C777FF] z-1 font-[700] py-[10px] mt-[22px]">
        <p className="text-[24px] ">
          Welcome to Hum Finance, your single point of access to
          liquidity, yields and Dexs
        </p>
      </div>
      <div className="sm:flex items-center xl:gap-[100px] lg:gap-[50px] gap-5 mt-[20px]">
        <div className="flex flex-col gap-[57px] items-start px-[72px] w-[70%]">
          <h2 className=" lg:text-[50px] text-[40px] tracking-tighter font-[700] leading-[103%] xl:pr-24 pr-4 ">
            Swap, Borrow, Invest and Earn
          </h2>
          <p className=" xl:pr-28 pr-8 lg:text-[20px] ">
            Hum Finance is bringing on its unique yield and liquidity aggregator
            to allow you to swap with the best prices and, borrow with the
            lowest rates across protocols, allows Invest in high yield, low risk
            investments with DCA features and also allows you to view an manage
            your web3 asset portfolio using our AI portfolio management system.
          </p>
          <a
            className="flex items-start xl:px-[126px] lg:px-[80px] px-[50px] py-[16px] bg-[#FF7F50] lg:text-[20px] rounded-md"
            href="mailto:tylher123@gmail.com"
          >
            Get Access
          </a>
        </div>
        <div className="lg:w-[40%] md:w-[80%] w-[50vw] mx-auto">
          <img src={mockup} alt="" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default HeadlineSection;
