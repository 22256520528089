import React from "react";

const Contact = () => {
  return (
    <div className="pl-[71px] lg:py-[109px] py-[50px]  pr-[76px] bg-[#0b0721] text-white flex justify-between items-center">
      <p className="w-1/2 font-bold text-[16px] lg:text-[24px]">
        Would you like to learn more about our mobile app and experience many
        more features?
      </p>
      <a
        href="mailto:tylher123@gmail.com"
        className="bg-[#FF7F50] px-[40px] md:px-[70] lg:px-[126px] py-[16px] text-[16px] lg:text-[24px] font-medium rounded-md"
      >
        Talk To Us
      </a>
    </div>
  );
};

export default Contact;
