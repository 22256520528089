import React from "react";
import QuestionSvg from "../assets/images/bi_question-diamond-fill.svg";
import { useState } from "react";
import { questionsArray } from "../helper/faq_data";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

console.log(questionsArray);
const Faq = () => {
  const [questions, setQuestions] = useState(questionsArray);
  const handleClick = (questionId) => {
    setQuestions((prevState) => {
      return prevState.map((item, id) =>
        id === questionId ? { ...item, viewable: !item.viewable } : item
      );
    });
  };

  return (
    <div className="my-[100px] px-[75px] ">
      <h2 className=" text-[40px] font-bold text-center">
        Frequently Asked Questions
      </h2>

      <div className="grid grid-cols-2 gap-[10%] mt-14">
        <div className="flex flex-col gap-8">
          {questions.map(({ question, answers, viewable }, id) => {
            return (
              <div>
                <h4
                  key={id}
                  className=" text-[25px] font-bold flex justify-between items-center"
                >
                  {question}{" "}
                  {viewable ? (
                    <IoIosArrowUp onClick={() => handleClick(id)} />
                  ) : (
                    <IoIosArrowDown onClick={() => handleClick(id)} />
                  )}
                </h4>
                {answers.list ? (
                  <div
                    className="hidden"
                    style={viewable ? { display: "block" } : {}}
                  >
                    <p className="font-semibold">{answers.explanation}</p>
                    {Object.entries(answers.list).map(([key, value]) => {
                      return (
                        <>
                          {typeof value !== "object" ? (
                            <div className="gap-1 text-sm  mb-2">
                              <span className=" font-semibold w-fit mr-2">
                                {key}:
                              </span>
                              <p className=" inline">{value}</p>
                            </div>
                          ) : (
                            <div className="gap-1 text-sm  mb-2">
                              <span className=" font-semibold w-fit mr-2">
                                {key}:
                              </span>
                              <div className=" inline">
                                <span>{value.explanation}:</span>
                                <ul>
                                  {value.list.map((item) => {
                                    return (
                                      <li className=" italic list-disc list-inside">
                                        {item}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <p
                    className=" hidden"
                    style={viewable ? { display: "block" } : {}}
                  >
                    {answers.explanation}
                  </p>
                )}

                {answers.process ? (
                  <ul
                    className=" hidden"
                    style={viewable ? { display: "block" } : {}}
                  >
                    {answers.process.map((item) => {
                      return (
                        <li className=" italic list-disc list-inside text-sm ">
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col justify-center items-center bg-gray-200 border-[1px] border-[#161C3D]  h-[80vh]">
          <div className=" flex flex-col items-center gap-6">
            <img src={QuestionSvg} alt="" />
            <p className="text-[20px] font-bold">Have a different question?</p>
            <a
              href="mailto:tylher123@gmail.com"
              className=" bg-[#FF7F50] font-medium text-white text-[20px] w-full text-center rounded-sm py-2"
            >
              Shoot a DM
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
