export const questionsArray = [
  {
    question: "What is Hum Finance?",
    answers: {
      explanation:
        "Hum Finance is a yield aggregator protocol that allows users to earn compound interest on their staked assets. Hum Finance achieves this by automatically depositing user assets into a variety of yield-generating protocols, such as Aave, Compound, and Uniswap.",
    },
    viewable: false,
  },
  {
    question: "How does Hum Finance work?",
    answers: {
      explanation:
        "Hum Finance is a decentralized protocol, which means that it is not owned or operated by any single entity. The protocol is governed by a community of users who vote on proposals that affect the protocol.",
    },
    viewable: false,
  },
  {
    question: "What are the benefits of using Hum Finance?",
    answers: {
      explanation:
        "There are several benefits to using Hum Finance, including: ",
      list: {
        "Automated yield generation":
          "Hum Finance automatically deposits user assets into a variety of yield-generating protocols, which saves users time and effort.",
        "Risk diversification":
          "Hum Finance spreads user assets across a variety of yield-generating protocols, which helps to reduce risk.",
        "Low fees":
          "Hum Finance charges low fees, which means that users keep more of their earnings",
        "Low Risk": {
          explanation:
            "Hum Finance provides many features to allow you to reduce the risk to your investments as much as possible. Some of them are",
          list: [
            "Inbuilt decentralized Wallet with 2FA to avoid your assets being stolen",
            "DCA Investment to reduce impermanent loss by investing parts of your intended investments over a period of time",
            "Partial investment which occurs in loan app investments where you can both accrue rewards from investing and borrow against part of your investments.",
          ],
        },
      },
    },
    viewable: false,
  },
  {
    question: "What are the risks of using Hum Finance?",
    answers: {
      explanation:
        "There are a few risks associated with using Hum Finance, including:",
      list: {
        "Protocol risk":
          " The Hum Finance protocol is still under development and audit.",
        "Smart contract risk":
          "The Hum Finance protocol uses smart contracts, which are pieces of code that are stored on the blockchain.",
        "Impermanent loss":
          "When users deposit assets into liquidity pools, they are exposed to the risk of impermanent loss. Impermanent loss occurs when the price of the assets in the liquidity pool changes relative to the price of the assets when they were deposited. But we mitigate that by providing the DCA investment option so that you spread your investments over a period of time, but the risk is not fully removed just mitigated.",
      },
    },
    viewable: false,
  },
  {
    question: "How can I get started with Hum Finance?",
    answers: {
      explanation: "To get started with Hum Finance, you will need to:",
      process: [
        "Download the app",
        "Create a Hum Finance account.",
        "Deposit assets into Hum Finance.",
        "Choose the yield-generating opprotunities that you want to participate in.",
        "Sit back and earn compound interest!",
      ],
    },
    viewable: false,
  },
];
