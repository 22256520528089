import CardsDispay from "./components/cards-dispay";
import HeadlineSection from "./components/headline";
import Carousel from "./components/carousel";
import MockupDisplay from "./components/mockup-display";
import Contact from "./components/contact";
import Faq from "./components/faq";
import Footer from "./components/footer";
import { mockupImagesArray } from "./helper/mockImages";
const data = [
  {
    screenTitle: "Get Started",
    description:
      "  Connect to enjoy every feature on the Hum Finance mobile app.",
  },
  {
    screenTitle: "Borrow",
    description:
      " Borrowing just made easier, choose your token and chain & voila!",
  },
  {
    screenTitle: "Swap",
    description:
      "  You don’t have to stress like before becuase with our swap method, you get it just right.",
  },
  {
    screenTitle: "Portfolio",
    description:
      "  View your assests and access all your settings and activate the AI management system to help you invest and ensuring high rewards",
  },
];

function App() {
  return (
    <div className="w-[100%]">
      <HeadlineSection />
      <CardsDispay />
      <Carousel>
        {data.map(({ screenTitle, description }, id) => {
          return (
            <MockupDisplay
              title={screenTitle}
              description={description}
              id={id}
              img={mockupImagesArray[id]}
            />
          );
        })}
      </Carousel>
      <Contact />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
