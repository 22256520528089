import React from "react";
import FeatureCard from "./feature_card";
import SwapIcon from "../assets/images/swap.svg";
import LendIcon from "../assets/images/dollar-cash-svgrepo-com 1.svg";
import Connect from "../assets/images/fa6-solid_wallet.svg";

const cardsArr = [
  {
    name: "Your wallet, your control",
    description:
      " Access bottomless liquidity in any chain without ever giving up your private keys. Simply connect your external wallet and execute your intended transaction. Let AI manage your portfolio for low-risk, high-reward investing while you sleep.!",
    icon: Connect,
  },

  {
    name: "Swap Coins",
    description:
      "Access liquidity to swap/borrow across crypto markets and across supported chains without the need for bridging, from swap pools to lending pools of popular dexs on one decentralized platform",
    icon: SwapIcon,
  },
  {
    name: "Invest in Tokens and Hedge your funds",
    description:
      "Get access to multiple investment opportunities across supported chains as yields and invest with minimal risk and high rewards.",
    icon: LendIcon,
  },
];

const CardsDispay = () => {
  return (
    <div className="py-[70px] px-[69px]">
      <div className="flex  justify-between items-start ">
        <h2 className="font-[700] text-[40px] lg:text-[60px] text-[#0b0721 lg:leading-[64.7px] w-[40%]">
          Hum Finance App Features
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[20px] mt-[100px]">
        {cardsArr.map((card, id) => {
          const { name, description, icon } = card;
          return (
            <FeatureCard
              name={name}
              icon={icon}
              num={id + 1}
              desc={description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CardsDispay;
