import React from "react";
import BackgroundSvg from "../assets/images/background.svg";

const MockupDisplay = ({ id, title, description, img }) => {
  return (
    <div
      className="h-[100vh] flex items-start box px-[72px] py-10 justify-between "
      style={{
        backgroundImage: `url(${BackgroundSvg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <img src={img} className="h-[80vh]" />
      <div className="flex flex-col items-end justify-start gap-20 w-1/2">
        <div className="h-[100px] w-[100px] bg-black rounded-full flex items-center justify-center">
          <p className="text-6xl text-white">0{id + 1}</p>
        </div>
        <div className="flex flex-col items-end text-[#0b0721]">
          <h2 className="w-fit text-end text-7xl font-bold">{title}</h2>
          <p className="text-end font-medium text-2xl">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default MockupDisplay;
