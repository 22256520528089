import React from "react";
import AppleIcon from "../assets/images/AppleIcon.svg";
import GoogleIcon from "../assets/images/Icon.svg";
import logo from "../assets/images/humfinance-logo.svg";

const Footer = () => {
  return (
    <div className="bg-[#0b0721] text-white flex items-center justify-center gap-4 flex-col pt-8 pb-24">
      <div className=" flex items-center gap-1 text-5xl font-bold justify-center">
        <h2>Hum</h2>
        <img src={logo} alt="" width={50} />
        <h2>Finance</h2>
      </div>
      <div className="text-white flex gap-5">
        <button className="bg-black flex gap-3 items-center  p-2 px-3 rounded-md">
          <img src={AppleIcon} alt="" width={40} />
          <div className="flex flex-col items-start justify-center">
            <p className=" font-thin leading-3 text-sm">Download on the</p>
            <p className=" font-semibold text-[28px] leading-6">App Store</p>
          </div>
        </button>
        <button className="bg-black flex gap-3 items-center  p-2 px-3 rounded-md">
          <img src={GoogleIcon} alt="" width={40} />
          <div className="flex flex-col items-start justify-center ">
            <p className=" font-thin leading-3 text-[12px]">GET ON</p>
            <p className=" font-semibold text-[24px] leading-5">Google Play</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Footer;
