import GetStarted from "../assets/images/get-started-mockup.svg";
import BorrowPage from "../assets/images/borrow-mockup.svg";
import SwapPage from "../assets/images/swap-mockup.svg";
import PortfolioPage from "../assets/images/portfolio-mockup.svg";

export const mockupImagesArray = [
  GetStarted,
  BorrowPage,
  SwapPage,
  PortfolioPage,
];
