import React, { useEffect, useState } from "react";

const Carousel = ({ children }) => {
  const [currentPos, setCurrentPos] = useState(0);
  const [length, setLength] = useState(children.length);
  const [touchPosition, setTouchPosition] = useState(null);
  const [autoplay, setAutoplay] = useState(null);
  const [isCleared, setIsCleared] = useState(false);

  const handleTouchStart = (e) => {
    if (isCleared === false) {
      clearTimeout(autoplay);
      setIsCleared(true);
    }
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchPosition == null) return;
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }
    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
    setIsCleared(false);
  };

  function autoSlide() {
    currentPos === length - 1
      ? setCurrentPos(0)
      : setCurrentPos(currentPos + 1);
  }

  useEffect(() => {
    if (touchPosition == null && isCleared == false) {
      setAutoplay(setTimeout(autoSlide, 5000));
    }
  }, [currentPos, touchPosition, length]);

  useEffect(() => {
    setLength(children.length);
  }, [length]);

  const prev = () => {
    if (currentPos > 0) {
      setCurrentPos((prevState) => --prevState);
    }
  };

  const next = () => {
    if (currentPos < length - 1) {
      setCurrentPos((prevState) => prevState + 1);
    }
  };
  return (
    <div className="flex flex-col w-full">
      <div className=" flex relative w-full">
        <div
          className=" overflow-hidden w-full h-full"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className=" carousel-content flex w-full"
            style={{
              transform: `translateX(-${currentPos * 100}%)`,
            }}
          >
            {children}
          </div>
          <div className="w-[30%] grid grid-cols-4 z-10 gap-2 absolute bottom-[100px] right-[72px]">
            {Array.from({ length: 4 }, (_, i) => i).map((box) => {
              if (box === currentPos) {
                return (
                  <div
                    className="h-[10px] bg-[#0b0721] "
                    onClick={() => {
                      clearTimeout(autoplay);
                      setIsCleared(true);
                      setCurrentPos(box);

                      setTimeout(setIsCleared(false), 3000);
                    }}
                  ></div>
                );
              }

              return (
                <div
                  className="h-[10px] bg-gray-400"
                  onClick={() => {
                    clearTimeout(autoplay);
                    setIsCleared(true);
                    setCurrentPos(box);

                    setTimeout(setIsCleared(false), 3000);
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
